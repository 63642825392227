export default {
  banner: {
    title: "Contact Us",
    image: {
      src: require("@assets/img/contact-us.png"),
      placeholder: require("@assets/img/contact-us_thumb.png"),
      width: 1366,
      height: 318,
    },
  },
  subtitle: "All fields are required",
  fields: [
    {
      name: "jobTitle",
      label: "What is your job title?",
      textOption: "Select a job title",
      type: "select",
      options: [
        "CEO/ Executive Director",
        "CMO/ Clinical Director",
        "Administrator",
        "Program Manager",
        "Behavioral Health Clinician",
        "Patient",
      ],
    },
    { name: "fullName", label: "Full name" },
    { name: "company", label: "Organization / Company" },
    { name: "email", label: "Email", type: "email", half: true },
    { name: "phone", label: "Phone number", type: "number", half: true },
    {
      name: "role",
      label: "Your general role",
      textOption: "Select a role",
      type: "select",
      half: true,
      options: ["Payer", "Provider", "Employer", "Other"],
    },
  ],
  textareaField: {
    name: "description",
    label:
      "How can we help? Please describe your interest in Optum products or services",
  },
  agree: `By clicking the "Continue" button, you are agreeing to the <a href="https://www.optum.com/terms-of-use.html">Optum Terms of Use</a> and <a href="https://www.optum.com/privacy-policy.html">Privacy Policy.</a>`,
  confirmLabel: "Confirm",
};
