import React from "react";
import scss from "./style.scss";
import cn from "classnames";

export const Heading = ({
  type,
  uppercase,
  className,
  weight,
  withMargin,
  children,
}) => (
  <div
    className={cn(
      scss.default,
      type === "h2" ? scss.h2 : type === "h3" ? scss.h3 : scss.h1,
      uppercase && scss.uppercase,
      withMargin && scss.withMargin,
      className
    )}
    style={{ fontWeight: weight }}
  >
    {children}
  </div>
);

export const LabelwithEmphasis = ({ className, weight, children }) => (
  <div
    className={cn(scss.label, className)}
    style={{ fontWeight: weight }}
    dangerouslySetInnerHTML={{ __html: children }}
  />
);
