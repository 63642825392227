import React from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import ScrollTop from "@atom/ScrollTop";
import Footer from "@molecule/Footer";
import Header from "@molecule/Header";
import Home from "./Home";
import ContactUs from "./ContactUs";

const Screen = () => {
  let location = useLocation().pathname;

  return (
    <div
      style={{
        width: "100%",
        minHeight: "100vh",
        position: "relative",
      }}
    >
      <ScrollTop />
      <Header />
      <Switch>
        <Route exact path={"/"} component={Home} />
        <Route path={"/contact-us"} component={ContactUs} />
        <Redirect to={"/"} />
      </Switch>
      <Footer />
    </div>
  );
};

export default Screen;
