import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import LinkButton from "@atom/LinkButton";
import PageCenter from "@atom/PageCenter";
import OptumLogo from "@assets/optum-logo.svg";
import MenuIcon from "@assets/menu.svg";
import CloseIcon from "@assets/x.svg";
import PrimaryLinks from "../PrimaryLinks";
import Sidebar from "../Sidebar";
import style from "./style.scss";
import cn from "classnames";

const primaryLinks = [
  // { label: "About", url: "#About" },
  { label: "System of Care", url: "#System of Care" },
  { label: "Providers", url: "#Providers" },
  { label: "Working together", url: "#Working Together" },
  { label: "Solutions", url: "#Solutions" },
  // { label: "Employees", url: "#Employees" },
];

const Header = () => {
  let location = useLocation();
  const [open, setOpen] = useState(false);
  const [home, setHome] = useState(true);

  useEffect(() => {
    setOpen(false);
    if (location.pathname == "/contact-us" && home) setHome(false);
    else if (location.pathname != "/contact-us" && !home) setHome(true);
  }, [location]);

  return (
    <PageCenter rootClass={cn(style.root)}>
      <Sidebar open={open} links={primaryLinks} />
      <div className={cn(style.header)}>
        {home ? (
          <div className={cn(style.menu)}>
            {open ? (
              <CloseIcon onClick={() => setOpen(false)} />
            ) : (
              <MenuIcon onClick={() => setOpen(true)} />
            )}
          </div>
        ) : null}
        <div className={cn(style.logo)}>
          <LinkButton to={"/"}>
            <OptumLogo />
          </LinkButton>
        </div>
      </div>
      {home ? <PrimaryLinks links={primaryLinks} /> : null}
    </PageCenter>
  );
};

export default Header;
