import React from "react";
import Anchor from "@atom/Anchor";
import PageCenter from "@atom/PageCenter";
import style from "./style.scss";
import cn from "classnames";

const main =
  "© 2022 Optum, Inc. All rights reserved. Optum® is a registered trademark of Optum, Inc. Stock photos used.";
const links = [
  { label: "Privacy Policy", url: "https://www.optum.com/privacy-policy.html" },
  { label: "Terms of use", url: "https://www.optum.com/terms-of-use.html" },
  { label: "Accessibility", url: "https://www.optum.com/accessibility.html" },
];

const Footer = () => (
  <PageCenter rootClass={cn(style.root)} contentClass={cn(style.footer)}>
    <p>{main}</p>
    <p>
      {links.map(({ label, url }, i) => (
        <label key={label}>
          <Anchor href={url}>{label}</Anchor>
          {i == 2 ? null : <span>|</span>}
        </label>
      ))}
    </p>
  </PageCenter>
);

export default Footer;
