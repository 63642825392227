import React from "react";
import Anchor from "@atom/Anchor";
import style from "./style.scss";
import cn from "classnames";

const PrimaryLinks = ({ links }) => (
  <div className={cn(style.content)}>
    {links.map(({ label, url }, i) => (
      <div key={i} className={cn(style.link)}>
        <Anchor href={url} target={"_current"}>
          {label}
        </Anchor>
      </div>
    ))}
  </div>
);

export default PrimaryLinks;
