import React from "react";
import Anchor from "@atom/Anchor";
import PageCenter from "@atom/PageCenter";
import ProgressiveImage from "@atom/ProgressiveImage";
import { Heading } from "@atom/Typography";
import style from "./style.scss";
import cn from "classnames";

const Item = ({
  image,
  label,
  sublabel,
  actionLabel = "Learn more",
  actionURL,
}) => (
  <div className={cn(style.item)}>
    <ProgressiveImage {...image} />
    <div className={cn(style.text)}>
      <Heading type={"h3"}>
        {label}
        <p>{sublabel}</p>
      </Heading>
      <Anchor withArrow href={actionURL}>
        {actionLabel}
      </Anchor>
    </div>
  </div>
);

export default ({ title, subtitle, items, id }) => (
  <PageCenter rootClass={cn(style.root)} id={id}>
    <Heading withMargin type={"h3"}>
      {title}
    </Heading>
    <p className={cn(style.subtitle)}>{subtitle}</p>
    <div className={cn(style.items)}>
      {items.map((value, i) => (
        <Item {...value} key={i} />
      ))}
    </div>
  </PageCenter>
);
