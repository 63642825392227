import React from "react";
import RightArrow from "@assets/right-arrow.svg";
import style from "./style.scss";
import cn from "classnames";

export default ({
  className,
  href = "#",
  target = "_blank",
  download,
  children,
  withArrow,
  ...rest
}) => (
  <a
    className={cn(style.anchor, className)}
    target={href !== "#" ? target : null}
    {...{ href, download }}
    {...rest}
  >
    {children}
    {withArrow && <RightArrow />}
  </a>
);
