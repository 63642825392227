import React from "react";
import Anchor from "@atom/Anchor";
import Button from "@atom/Button";
import LinkButton from "@atom/LinkButton";
import PageCenter from "@atom/PageCenter";
import ProgressiveImage from "@atom/ProgressiveImage";
import { Heading } from "@atom/Typography";
import style from "./style.scss";
import cn from "classnames";

const TextBlock = ({ label, sublabel, links, button }) => (
  <div className={cn(style.text)}>
    <Heading type={"h2"} withMargin>
      {label}
    </Heading>
    <p>{sublabel}</p>
    {links && (
      <div className={cn(style.links)}>
        {links.map(({ label, url, to }, i) => (
          <Anchor key={i} href={url || to} target={to && "_current"} withArrow>
            {label}
          </Anchor>
        ))}
      </div>
    )}
    {button && (
      <LinkButton to={button.to}>
        <Button>{button.label}</Button>
      </LinkButton>
    )}
  </div>
);

export default ({ image, text, id, className }) => (
  <PageCenter rootClass={cn(style.root, className)} id={id}>
    <div className={cn(style.item, !image && style.noImg)}>
      {image && <ProgressiveImage {...image} />}
      <TextBlock {...text} />
    </div>
  </PageCenter>
);
