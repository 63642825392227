export default {
  banner: {
    title:
      "Solutions for a broad spectrum of mental health needs with Optum Behavioral Care ",
    button: { label: "Request more information", to: "/contact-us" },
    image: {
      src: require("@assets/img/home.png"),
      placeholder: require("@assets/img/home_thumb.png"),
      height: 420,
      width: 736,
    },
  },
  graph: {
    title:
      "A Care Delivery System Designed to Meet Clinician, Provider, & Patient Needs",
    subtitle:
      "Individuals' needs always determine the ideal treatment settings. Our care delivery system offers multiple solutions to support patients with low-severity conditions to high-severity conditions, and everything in between. This allows us to provide the most effective care for their entire treatment journey.",
    keypoints: [
      {
        icon: require("@assets/img/g1.png"),
        label: "Self-directed care",
        list: ["Self-help apps", "Digital therapies"],
      },
      {
        icon: require("@assets/img/g3.png"),
        label: "Traditional behavioral care",
        list: [
          "In person Behavioral Health clinics",
          "Virtual BH visits",
          "Telepresence to clinic sites",
        ],
      },
      {
        icon: require("@assets/img/g2.png"),
        label: "Primary care integration",
        list: [
          "Collaborative Care Model (CoCM)",
          "Integrated Behavioral  Health",
        ],
      },
    ],
  },
  articles: {
    title: "Behavioral Care Providers",
    subtitle:
      "Through mobile apps, virtual care, in-person visits, and telepresence into partner facilities, Optum Behavioral Care provides a full suite of behavioral health services. This includes evidence-based psychotherapy, psychopharmacology treatment, medical-behavioral integration in primary care, and subspecialty care. Learn more about our behavioral care providers below.",
    items: [
      {
        image: {
          src: require("@assets/img/refresh.png"),
          placeholder: require("@assets/img/refresh_thumb.png"),
          width: 320,
          height: 220,
        },
        label: "Refresh Mental Health ",
        sublabel:
          "Refresh Mental Health promotes clinical excellence and access to high quality mental healthcare by partnering with leading practices with specialized programs throughout the United States.",
        actionURL: "https://www.refreshmentalhealth.com/",
      },
      {
        image: {
          src: require("@assets/img/ableTo.png"),
          placeholder: require("@assets/img/ableTo_thumb.png"),
          width: 320,
          height: 220,
        },
        label: "AbleTo",
        sublabel:
          "Holistic, virtual and online mental health counseling and coaching tailored to the patient’s needs and led by a select group of licensed professionals who receive ongoing training in cognitive behavioral therapy and other evidence-based psychotherapy approaches.",
        actionURL: "https://www.ableto.com/",
      },
    ],
  },
  workingTogether: {
    image: {
      src: require("@assets/img/working.png"),
      placeholder: require("@assets/img/working_thumb.png"),
      width: 461,
      height: 416,
    },
    text: {
      label: "Belong, Engage, Transform",
      sublabel:
        "We offer a variety of solutions that meet the unique needs of our workforce and the patients they serve. From clinical operations such as credentialing to business operations such as contracting, we provide organizational support that allows our providers to focus on what matters -- providing care. Optum Behavioral Care shares your mission to take care of people, we bring the technology, resources, and expertise to launch new integrated behavioral health programs quickly and effectively, and to sustain those programs for the long run. Interested in joining our team or working with us? Learn more below.",
      links: [
        {
          label: "Careers for Mental Health Clinicians",
          url: "https://refreshmentalhealth.com/careers/current-openings/",
        },
        {
          label: "Corporate Careers at Optum",
          url: "https://www.optum.com/careers.html",
        },
        {
          label: "Contact Us",
          to: "/contact-us",
        },
      ],
    },
  },
  solutions: {
    label:
      "Innovative Solutions for Employers, Payers, and Government Programs",
    sublabel:
      "To meet the growing need for mental health services, our products and solutions offer employers, payers, and government programs behavioral health benefits and services to fit their needs. We connect people to an extensive network of providers, innovative tools that improve access to care and resources that inspire them to be more engaged in their own wellness.",
    button: { label: "Request more information", to: "/contact-us" },
  },
};
