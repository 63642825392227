import React from "react";
import Select from "../Select";
import style from "./style.scss";
import cn from "classnames";

export const TextArea = ({ label, name, value, ...rest }) => (
  <div className={cn(style.input, style.textarea)}>
    <p>{label}:</p>
    <textarea id={name} name={name} rows="5" cols="33" {...rest}></textarea>
  </div>
);

export default ({
  name,
  label,
  half,
  type,
  options = [],
  children,
  ...rest
}) => (
  <div className={cn(style.input, half && style.half)}>
    <p>{label}:</p>
    {type === "select" ? (
      <Select values={options} {...rest} />
    ) : (
      <input {...{ name }} type={type || "text"} {...rest}>
        {children}
      </input>
    )}
  </div>
);
