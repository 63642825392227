import React from "react";
import PageCenter from "@atom/PageCenter";
import Banner from "@molecule/Banner";
import Articles from "@organism/Articles";
import ImageTextBlock from "@organism/ImageTextBlock";
import Graph from "@organism/Graph";
import data from "./data";
import style from "./style.scss";
import cn from "classnames";

export default () => (
  <div className={cn(style.home)}>
    <Banner {...data.banner} />
    <Graph {...data.graph} />
    <Articles id={"Providers"} {...data.articles} />
    <ImageTextBlock {...data.workingTogether} id={"Working Together"} />
    <ImageTextBlock
      text={data.solutions}
      className={cn(style.sky)}
      id={"Solutions"}
    />
  </div>
);
