import React, { useEffect, useState } from "react";
import axios from "axios";
import Button from "@atom/Button";
import Input, { TextArea } from "@atom/Input";
import PageCenter from "@atom/PageCenter";
import ProgressiveImage from "@atom/ProgressiveImage";
import { LabelwithEmphasis } from "@atom/Typography";
import Banner from "@molecule/Banner";
import data from "./data";
import style from "./style.scss";
import cn from "classnames";

export default () => {
  const [formInput, setFormInput] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);

    axios
      .post("/sendMail.php", formInput)
      .then((response) => {
        if (response.data && response.data.success) {
          alert("Form sent!");
          setSubmitted(false);
          setFormInput([]);
        }
      })
      .catch(function (error) {
        console.warn("Request Error:", error);
        setSubmitted(false);
      });
  };

  const handleChange = (e, name) => {
    setFormInput((prevState) => ({
      ...prevState,
      [name]: e.target.value,
    }));
  };

  return (
    <div>
      <Banner {...data.banner} full={true} />
      <PageCenter contentClass={cn(style.content)}>
        <p>{data.subtitle}</p>
        <form
          className={cn(style.form)}
          name={"ContactUsForm"}
          onSubmit={(e) => handleSubmit(e)}
        >
          {data.fields.map((value, i) => (
            <Input
              {...value}
              key={i}
              value={formInput[value.name] || ""}
              onChange={(e) => handleChange(e, value.name)}
              required
            />
          ))}
          <TextArea
            {...data.textareaField}
            value={formInput[data.textareaField.name] || ""}
            onChange={(e) => handleChange(e, data.textareaField.name)}
            required
          />
          <LabelwithEmphasis>{data.agree}</LabelwithEmphasis>
          <Button disabled={submitted} type={"submit"}>
            {data.confirmLabel}
          </Button>
        </form>
      </PageCenter>
    </div>
  );
};
