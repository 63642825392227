import React from "react";
import Button from "@atom/Button";
import LinkButton from "@atom/LinkButton";
import PageCenter from "@atom/PageCenter";
import ProgressiveImage from "@atom/ProgressiveImage";
import { Heading } from "@atom/Typography";
import style from "./style.scss";
import cn from "classnames";

const Banner = ({ image, title, button, full }) => (
  <PageCenter
    rootClass={cn(style.root)}
    contentClass={cn(style.container, full && style.full)}
  >
    {full ? (
      <>
        <ProgressiveImage {...image} />
        <Heading className={cn(style.heading)}> {title}</Heading>
      </>
    ) : (
      <div className={cn(style.banner)}>
        <Heading className={cn(style.heading)}>
          {title}
          {button && (
            <LinkButton to={button.to}>
              <Button>{button.label}</Button>
            </LinkButton>
          )}
        </Heading>
        <div className={cn(style.imgdiv)} />
        <ProgressiveImage {...image} />
      </div>
    )}
  </PageCenter>
);

export default Banner;
