import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Anchor from "@atom/Anchor";
import LinkButton from "@atom/LinkButton";
import style from "./style.scss";
import cn from "classnames";

export default ({ open, links }) => {
  let location = useLocation().pathname;

  useEffect(() => {
    const e = document.getElementById("side");
    if (open) setTimeout(() => e.focus(), 720);
  }, [open]);

  return (
    <div className={cn(style.sidebar, open ? style.slideIn : style.slideOut)}>
      <a href={"#"} id={"side"} className={cn(style.side)} />
      {links.map(({ label, url, to, target }, i) => (
        <div key={i} className={cn(style.link)}>
          <Anchor href={url} target={"_current"}>
            {label}
          </Anchor>
        </div>
      ))}
    </div>
  );
};
