import React from "react";
import { Heading } from "@atom/Typography";
import PageCenter from "@atom/PageCenter";
import style from "./style.scss";
import cn from "classnames";

export default ({ title, subtitle, keypoints }) => (
  <PageCenter id={"System of Care"}>
    <div className={cn(style.graph)}>
      <Heading type={"h2"} withMargin>
        {title}
      </Heading>
      {subtitle}
      <div className={cn(style.keypoints)}>
        {keypoints.map(({ icon, label, list }, _i) => (
          <div key={label} className={cn(style.item)}>
            <img src={icon} />
            <Heading type={"h3"}>{label}</Heading>
            <ul>
              {list.map((value, a) => (
                <li key={a}>{value}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  </PageCenter>
);
